import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Beispiel from '../assets/images/Teilprothese.jpg'
import Beispiel_1 from '../assets/images/ProtheseMitGeschiebe.jpg'
import Layout from '../components/layout'

const TestWrapper = styled.div`
  img {
    top: 0;
    height: 40%;
    width: 45%;
  }
`
 /* width: auto;
    height: 200px;
    margin: 3em !important; */

export default ({ data }) => {
  return (
    <Layout>
      <Helmet title="prothesen" />
      <div id="main">
        <hr></hr>
        <section className="main">
        <div
            dangerouslySetInnerHTML={{
              __html: data.allMarkdownRemark.edges[0].node.html,
            }}
          ></div>
          <TestWrapper>
            <img src={Beispiel} alt="" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img src={Beispiel_1} alt="" />
          </TestWrapper>
        </section>
      </div>
    </Layout>
  )
}


export const query = graphql`
  query prothesen {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "prothesen" } } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`

